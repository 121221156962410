import React from 'react'

const GoogleMap = () => (
    
    <div style={{ height: '100%', width: '100%' }}>

        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2557.7990987195994!2d14.486076576861727!3d50.12748121056511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470bec98d8f58f97%3A0x29e14ad9955f28ec!2sDigi%20Office%2C%20s.r.o.!5e0!3m2!1scs!2sus!4v1680264415711!5m2!1scs!2sus"
            width="100%" height="100%" allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
)

export default GoogleMap
