import React from "react"
import Layout from "../components/Layout/Layout"
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import GoogleMap from "../components/Components/GoogleMap";
import ContactFormSpree from "../components/Components/ContactFormSpree";

export default function Kontakt() {
    return (
        <Layout>
            <div className="contact">
                <div className="headerBlue">
                    <div className="container">
                        <h1 className="pt-5 pb-5 d-none d-md-block">Pojďme se sejít při nezávazné konzultaci</h1>
                        <h1 className="pt-5 pb-5 text-center d-block d-md-none">Kontaktujte nás</h1>
                    </div>
                </div>
                <div className="container pt-4 pb-5">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="picCards h-100">
                                <div className="cardGrey text-center bgGrey">
                                    <div className="card-body">
                                        <div className="card-pic mx-auto">
                                            <div className={"d-none d-lg-block"}>
                                                <StaticImage imgClassName={"business"}
                                                             src="../assets/custom-style/images/ilustrace-04.svg"
                                                             alt="Obchod"
                                                />
                                            </div>
                                            <div className={"d-lg-none"}>
                                                <StaticImage imgClassName={"business"}
                                                             src="../assets/custom-style/images/icons/business.png"
                                                             alt={"Obchod"}
                                                />
                                            </div>
                                        </div>


                                        <h5 className="card-subtitle pt-4">Obchodní oddělení</h5>
                                        <div className="contact-information">
                                            <p><a href="mailto:obchod@digioffice.cz"
                                                  className="text-primary fw-bold contact-email">obchod@digioffice.cz</a>
                                            </p>
                                            <p><a href="tel:+420603773883" className="text-primary">+420 603 773 883</a>
                                            </p>
                                            <p className="mb-0"><a href="tel:+420222741841" className="text-primary">+420
                                                222 741 841</a></p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 ">
                            <div className="picCards h-100">
                                <div className="cardGrey text-center bgGrey">
                                    <div className="card-body">
                                        <div className="card-pic mx-auto">
                                            <div className={"d-none d-lg-block"}>
                                                <StaticImage imgClassName={"techsupport"}
                                                             src="../assets/custom-style/images/ilustrace-05.svg"
                                                             alt={"podpora"}
                                                />
                                            </div>
                                            <div className={"d-lg-none"}>
                                                <StaticImage imgClassName={"techsupport"}
                                                             src="../assets/custom-style/images/icons/helpdesk.webp"
                                                             alt={"podpora"}
                                                />
                                            </div>
                                        </div>
                                        <h5 className="card-subtitle pt-4">Technická podpora</h5>
                                        <div className="contact-information">
                                            <p><a href="mailto:podpora@digioffice.cz"
                                                  className="text-primary contact-email fw-bold">podpora@digioffice.cz</a>
                                            </p>
                                            <p><a href="tel:+420608773883" className="text-primary">+420 608 773 883</a>
                                            </p>
                                            <p className="mb-0"><a href="tel:+420222741843" className="text-primary">+420
                                                222 741 843</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 position-relative mt-5 mt-lg-0">
                            <h3 className="text-center d-block d-lg-none">Kontaktujte nás</h3>

                            <ContactFormSpree/>

                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row ms-auto pb-5 text-center text-md-start">
                        <div className="col-lg col-md-4 col-sm-6">
                            <StaticImage imgClassName={"d-inline-block"}
                                         src="../assets/custom-style/images/icons/location.png"
                                         alt={"ikona - lokace"}
                            />
                            <p className="mb-0">Digi Office s.r.o.</p>
                            <small className="text-muted text-small">
                                Lovosická 439/42<br/>
                                190 00 Praha 9
                            </small>
                        </div>
                        <div className="col-lg col-md-4 col-sm-6 pt-sm-0 pt-3">
                            <StaticImage imgClassName={"d-inline-block"}
                                         src="../assets/custom-style/images/icons/sponky.png"
                                         alt={"Fakturační údaje"}
                            />
                            <p className="mb-0">Fakturační údaje</p>
                            <small className="text-muted text-small">
                                IČ: 242 24 651<br/>
                                DIČ: 242 24 651
                            </small>
                        </div>
                        <div className="col-lg col-md-4 col-sm-6 pt-md-0 pt-3">
                            <StaticImage imgClassName={"d-inline-block"}
                                         src="../assets/custom-style/images/icons/pages.png"
                                         alt={"ikona - stránky"}
                            />
                            <p className="mb-0">Fakturace</p>
                            <small className="text-muted text-small">
                                <a href="mailto:fakturace@digioffice.cz"
                                   className="d-inline-block text-muted">fakturace@digioffice.cz</a><br/>
                                <a href="tel:+420222741841" className="d-inline-block text-muted">+420 222 741 841</a>
                            </small>
                        </div>
                        <div className="col-lg col-md-4 col-sm-6 pt-lg-0 pt-3">
                            <StaticImage imgClassName={"d-inline-block"}
                                         src="../assets/custom-style/images/icons/credit-card-solid.webp"
                                         alt={"Bankovní spojení"}
                            />
                            <p className="mb-0">Bankovní spojení</p>
                            <small className="text-muted text-small">
                                250046087/0300<br/>
                                Jsme plátci DPH
                            </small>
                        </div>
                        <div className="col-lg col-md-4  col-sm-6 pt-lg-0 pt-3">
                            <StaticImage imgClassName={"d-inline-block"}
                                         src="../assets/custom-style/images/icons/trezor.png"
                                         alt={"ikona - datová schránka"}
                            />
                            <p className="mb-0">Datová schránka</p>
                            <small className="text-muted text-small">
                                w2zajr7
                            </small>
                        </div>
                        <div className="col-lg col-md-4  col-sm-6 pt-lg-0 pt-3">
                            <StaticImage imgClassName={"d-inline-block"}
                                         src="../assets/custom-style/images/icons/time.png"
                                         alt={"ikona - provozní doba"}
                            />
                            <p className="mb-0">Provozní doba</p>
                            <small className="text-muted text-small">
                                po-pá 8:00-18:00<br/>
                                ostatní dle domluvy
                            </small>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md mb-2 mb-md-0">
                            <div className="card bgBlue border-0 h-100">
                                <div className="card-body pt-0 pb-3 pb-md-0">
                                    <div className="row">
                                        <div className="col-xl-8 col-lg-7 my-auto">
                                            <p className="mb-0">Požadujete technickou podporu?</p>
                                            <small>Zašlete nám autorizovaný požadavek přes náš HelpDesk</small>
                                        </div>
                                        <div className="col-xl-4 col-lg-5 text-center text-md-end my-auto">
                                            <Link to={'#'} type="button"
                                                  className="btn button-rounded-white rounded-pill d-inline-block mt-2 mt-md-0">HelpDesk&nbsp;
                                                <FontAwesomeIcon icon={faAngleRight}/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="card bgBlue border-0 h-100">
                                <div className="card-body pt-0 pb-3 pb-md-0 my-auto">
                                    <div className="row">

                                        <div className="col-xl-6 col-lg-7">
                                            <p>Technická podpora pomocí aplikace TeamViewer</p>

                                        </div>


                                        <div className="col-xl-6 col-lg-5 text-center text-md-end my-auto">
                                            <a href={"https://mamproblem.digioffice.cz/homepage/download"}>
                                                <StaticImage className="w-75 w-md-50"
                                                             src="../assets/custom-style/images/icons/teamviewer-logo.webp"
                                                             alt={"logo - teamviewer"}
                                                />
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12 col-lg-6 p-0 googleMap">
                            <GoogleMap/>
                        </div>
                        <div className="col-md-6 p-0">
                            <StaticImage className={"w-100 d-none d-lg-flex"} alt={"Kancelář"}
                                         src="../assets/custom-style/images/digioffice.jpg"/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
        ;
}